// Colors
$gray9: #434343;

// Font Weights
$regular: 400;
$medium: 500;
$semi-bold: 600;

// Using the breakpoints (BP) here: https://ant.design/components/grid/#Col
// TODO: Should either import these BP variables from our AntD library, or override them here.
$xs: 576px;

$profile-incomplete-field-highlight: 0px 0px 20px 4px rgba(50, 108, 240, 0.4);
$profile-incomplete-field-highlight-pulse: 0px 0px 20px 4px rgba(50, 108, 240, 0.1);

@font-face {
  font-family: Inter;
  src: url('./fonts/inter/Inter-VariableFont_slnt\,wght.woff2') format('woff2 supports variations'),
    url('./fonts/inter/Inter-VariableFont_slnt\,wght.woff2') format('woff2-variations');
  font-weight: $regular $semi-bold;
  font-display: swap;
}

@font-face {
  font-family: IBM Plex Mono;
  src: url('./fonts/IBM_Plex_Mono/IBMPlexMono-Regular.ttf') format('truetype');
  font-weight: $regular;
  font-display: swap;
}

@font-face {
  font-family: IBM Plex Mono;
  src: url('./fonts/IBM_Plex_Mono/IBMPlexMono-Medium.ttf') format('truetype');
  font-weight: $medium;
  font-display: swap;
}

@font-face {
  font-family: IBM Plex Mono;
  src: url('./fonts/IBM_Plex_Mono/IBMPlexMono-SemiBold.ttf') format('truetype');
  font-weight: $semi-bold;
  font-display: swap;
}

#root {
  height: 100vh;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Inter;
  font-weight: $regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

svg {
  vertical-align: baseline;
}

button {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  text-transform: none;
  -webkit-appearance: button;
  appearance: button;
  background-color: inherit;
  border: none;
  outline: none;
}

p {
  margin-bottom: 0;
}

button:focus {
  outline: none;
}

@mixin typography-base($letter-spacing: 0, $font-weight: $regular) {
  color: $gray9;
  letter-spacing: $letter-spacing;
  font-weight: $font-weight;
  padding: 0;
  margin: 0;
}

label {
  margin-bottom: 0px;
}

h1.ant-typography {
  @include typography-base(-0.01em, $semi-bold);
  font-size: 48px;
  line-height: 64px;
}

h2.ant-typography {
  @include typography-base(0, $semi-bold);
  font-size: 40px;
  line-height: 48px;
}

h3.ant-typography {
  @include typography-base(0, $semi-bold);
  font-size: 32px;
  line-height: 40px;
}

h4.ant-typography {
  @include typography-base(0, $semi-bold);
  font-size: 24px;
  line-height: 32px;
}

h5.ant-typography {
  @include typography-base(0, $semi-bold);
  font-size: 17px;
  line-height: 22px;
}

.body-large {
  @include typography-base();
  font-size: 17px;
  line-height: 28px;
}

.body-base {
  @include typography-base();
  font-size: 15px;
  line-height: 24px;
}

.body-small {
  @include typography-base();
  font-size: 13px;
  line-height: 20px;
}

.label-large {
  @include typography-base();
  font-size: 17px;
  line-height: 24px;
}

.label-base {
  @include typography-base();
  font-size: 15px;
  line-height: 22px;
}

.label-small {
  @include typography-base();
  font-size: 13px;
  line-height: 16px;
}

.overline-base {
  @include typography-base(0.04em);
  font-size: 15px;
  line-height: 20px;
}

.overline-small {
  @include typography-base(0.04em);
  font-size: 13px;
  line-height: 16px;
}

.overline-xsmall {
  @include typography-base(0.04em);
  font-size: 11px;
  line-height: 16px;
}

@mixin mobile {
  @media (max-width: #{$xs}) {
    @content;
  }
}

/* Sentry */
.sentry-error-embed .form-submit .powered-by {
  visibility: hidden !important;
}

/* For filters */
.twine {
  &__options-list {
    > button:hover,
    label:hover {
      background-color: #e2ecff;
    }
  }

  &__blue-pill {
    padding: 2px 8px;
    background-color: #326cf0 !important;
    border-color: #326cf0;
    color: white !important;
    border-radius: 4px;

    &:hover {
      background-color: #0354d6 !important;
    }

    &:active {
      background-color: #003fa5 !important;
    }
  }
}

/* Using font-weight: bold pushes right menu when a nav link is hovered */
/* https://dev.to/mingyena/fix-inline-elements-shifting-issue-in-bold-on-hover-4fb */
a {
  color: #326cf0;

  &:hover {
    text-decoration: underline;
    color: #0354d6;
  }

  &:active {
    color: #003fa5;
  }
}

.gray-scale-img {
  filter: saturate(0);
  opacity: 0.5;
}

.underline-on-hover:hover {
  text-decoration: underline !important;
}

.bold-on-hover:hover {
  -webkit-text-stroke: 0.5px;
  -webkit-text-stroke-color: initial;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.clickable {
  cursor: pointer;

  &:hover {
    box-shadow: none;
  }
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0px;
}

.page-wrapper {
  max-width: 1500px;
  padding: 50px;
  margin: auto;
}

.cpohq-page-wrapper {
  max-width: 1500px;
  margin: auto;
  padding: 24px 50px 50px 50px;
  display: flex;
  justify-content: center;
}

.cpohq-header-metadata {
  background-color: white;
  width: 100%;
  min-height: 72px;
  display: flex;
  justify-content: center;

  .ant-row {
    width: 100%;
    max-width: 1200px;
  }
}

@include mobile {
  .page-wrapper {
    padding: 16px;
  }

  .cpohq-page-wrapper {
    padding: 0px;
  }

  .cpohq-header-metadata {
    min-height: 56px;
  }

  .member-posts-wrapper {
    padding: 24px 0;
  }
}

.centered-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.green-switch-wrapper > .ant-switch-checked > .ant-switch-handle::before {
  background-color: #8ce3ba;
}

.green-switch-wrapper > .ant-switch {
  background-color: #e6e8ee70;
}

// grey button
.grey-btn {
  background-color: #f0f0f0 !important;
  border-color: #f0f0f0 !important;
  color: #8c8c8c !important;
}

.grey-btn:hover {
  border-color: #d9d9d9 !important;
  background-color: #d9d9d9 !important;
  color: #8c8c8c !important;
}

// green button
$brand-green-2: #00c282;
$brand-green-3: #00ad74;

.green-btn {
  background-color: $brand-green-2 !important;
  border-color: $brand-green-2 !important;
  color: white !important;
}

.green-btn:hover {
  border-color: $brand-green-3 !important;
  background-color: $brand-green-3 !important;
  color: white !important;
}

// gold button
.gold-btn {
  background-color: #faad14 !important;
  border-color: #faad14 !important;
  color: black !important;
}

.gold-btn:hover {
  background-color: #eea411 !important;
  border-color: #eea411 !important;
  color: black !important;
}

.purple-btn {
  .btn-callout {
    color: #0354d6;
    transition: all 0.4s ease;
  }

  &:active,
  &:focus,
  &:hover {
    background-color: #b37feb !important;
    border-color: #efdbff !important;
    color: white !important;
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08),
      0px 9px 28px 8px rgba(0, 0, 0, 0.05);

    .btn-callout {
      color: white;
      transition: all 0.4s ease;
    }
  }
}

.small-drawer {
  .ant-drawer-content-wrapper {
    display: flex;
    align-items: center;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    top: calc((100vh - 450px) / 2);
    height: 450px;
  }

  .ant-drawer-content {
    box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05),
      -12px 0 48px 16px rgba(0, 0, 0, 0.03);
    -webkit-box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05),
      -12px 0 48px 16px rgba(0, 0, 0, 0.03);
  }
}

.clickable-input {
  cursor: pointer;
  transition: all 0.4s ease;
}

.clickable-input:hover {
  transition: all 0.4s ease;
  background-color: #f0f2f5;
}

.input-follow-parent {
  input {
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
  }
}

@keyframes highlight-post-fade {
  0% {
    background-color: #f0f0f0;
  }

  15% {
    background-color: #d5e5ff;
  }

  80% {
    background-color: #d5e5ff;
  }

  100% {
    background-color: #f0f0f0;
  }
}

.post-highlight {
  animation: highlight-post-fade 5s 0.2s;
}

.light-grey-bg {
  background-color: #fafafa;
}

.shadow-light {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
}

/* ANTD OVERRIDES */
/* TODO: AntD has theme overrides that we can use to set global Twine colors.  */
/* This is done with babel. With SSR it maybe complex to implement theme overrides.   */
/* Therefore, the below styles will have to do, even though it may seem unnecessarily tedious. */

.ant-btn-background-ghost:hover {
  background-color: white !important;
  border-color: #2e69c7 !important;
  color: #2e69c7 !important;
}

.ant-btn-custom-cyan {
  background-color: #36cfc9;
  border-color: #36cfc9;
  color: white !important;

  &:focus {
    background-color: #36cfc9;
  }

  &:active {
    background-color: #36cfc9;
  }

  &:hover {
    background-color: #31b7b2;
  }
}

.ant-btn-custom-green {
  background-color: #e2ffe9;
  border-color: #e2ffe9;
  color: black;

  &:focus {
    background-color: #e2ffe9;
    border-color: #e2ffe9;
  }

  &:active {
    background-color: #e2ffe9;
    border-color: #e2ffe9;
  }

  &:hover {
    background-color: #d5f3dc;
    color: #43bc5e;
    border-color: #43bc5e;
  }
}

/* default button style overrides */
.ant-btn-sm {
  font-size: 13px;
  line-height: 16px;
}

.ant-btn-lg {
  font-size: 17px;
  line-height: 24px;
}

.ant-btn {
  font-weight: 500;

  background-color: #ffffff;
  border-color: #d9d9d9;

  &:hover,
  &:focus {
    color: #0354d6;
    border-color: #0354d6;
    background-color: #ffffff;
  }

  &:active {
    color: #0354d6;
    border-color: #0354d6;
    background-color: #e5edff;
  }
}

.ant-btn:not(.ant-btn-circle, .ant-btn-round) {
  border-radius: 6px;
}

.ant-btn.ant-btn-ghost {
  background-color: transparent; // fix overrides
}

.ant-btn.ant-btn-link {
  color: #326cf0;
  font-weight: 400;

  background-color: transparent;
  border-color: transparent;

  &:hover,
  &:focus {
    color: #0354d6;
  }

  &:active {
    color: #003fa5;
  }

  &:disabled {
    color: #bfbfbf;
  }
}

.ant-btn.ant-btn-link.ant-btn-loading {
  border-color: transparent;
  background-color: transparent;
}

.ant-btn.ant-btn-text {
  color: #434343;
  font-weight: 400;

  background-color: transparent;
  border-color: transparent;

  &:hover,
  &:focus {
    background-color: #f0f0f0;
    border-color: #f0f0f0;
  }

  &:active {
    background-color: #d9d9d9;
    border-color: #d9d9d9;
  }

  &:disabled {
    color: #bfbfbf;
    background-color: #ffffff;
    border-color: #ffffff;
  }
}

.ant-btn.ant-btn-text.ant-btn-loading {
  border-color: #ffffff;
  background-color: #ffffff;
}

.ant-btn-primary {
  font-weight: 500;

  background-color: #326cf0;
  border-color: #326cf0;

  &:hover,
  &:focus {
    color: #ffffff;
    background-color: #0354d6;
  }

  &:active {
    background-color: #003fa5;
  }
}

.ant-btn-primary.ant-btn-loading {
  background-color: #326cf0;
  border-color: #326cf0;
}

.ant-btn-dangerous.ant-btn-primary.ant-btn-loading {
  border-color: #ff4d4f !important;
  background: #ff4d4f !important;
}

// custom emphasized button
// add className='emphasized' to use
.ant-btn.emphasized {
  color: #434343;
  font-weight: 500;

  background-color: #00d38d;
  border-color: #00d38d;

  &:hover,
  &:focus {
    background-color: #00c282;
    border-color: #00c282;
  }

  &:active {
    background-color: #00ad74;
    border-color: #00ad74;
  }

  &:disabled {
    color: #bfbfbf;
    background-color: #f5f5f5;
    border-color: #d9d9d9;
  }
}

.ant-btn.ant-btn-loading.emphasized {
  background-color: #00d38d;
  border-color: #00d38d;
}

// custom  button - used as a control button for toggling popovers
// add className='dropdown' to use
.ant-btn.dropdown {
  color: #bfbfbf;
  padding: 0px 11px;

  &:hover,
  &:focus,
  &:active {
    border-color: #40a9ff;
  }

  &:disabled {
    color: #d9d9d9;
    background-color: #f5f5f5;
    border-color: #d9d9d9;
  }
}

/* end of default button overrides */

// Search

.ant-input-wrapper {
  .ant-input {
    border-radius: 6px 0 0 6px;
  }

  .ant-input-group-addon > button {
    border-radius: 0 6px 6px 0 !important;
  }
}

// Radio buttons
.ant-radio-group {
  .ant-radio-button:first-child,
  .ant-radio-button-wrapper:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .ant-radio-button:last-child,
  .ant-radio-button-wrapper:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .ant-radio-button-wrapper {
    &:hover {
      color: #0354d6;
    }
  }

  .ant-radio-button-wrapper-checked {
    background-color: #326cf0 !important;
    border-color: #326cf0 !important;

    &:hover {
      background-color: #0354d6 !important;
      border-color: #0354d6 !important;
    }

    &:active {
      background-color: #003fa5 !important;
      border-color: #003fa5 !important;
    }

    &:before {
      background-color: inherit !important;
    }
  }
}

// Checkbox
.ant-checkbox-inner {
  &:hover {
    border-color: #326cf0 !important;
  }
}

.ant-checkbox-indeterminate {
  .ant-checkbox-inner::after {
    background-color: #326cf0 !important;
  }
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    border-color: #326cf0 !important;
    background-color: #326cf0;
  }

  &:after {
    border: 1px solid #326cf0;
  }
}

// Forms
.ant-form-item-explain {
  margin-top: 4px;
  margin-bottom: 8px;
}

.ant-message-custom-content {
  display: flex;
}

// Modal
.ant-modal-content {
  overflow: auto;
  border-radius: 6px;
}

/* Date Range Picker */
.ant-picker-range .ant-picker-input > input {
  text-align: center;
}

.ant-popover-inner-content {
  color: black;
}

.ant-popover-title {
  padding: 8px 16px 8px !important;
  font-size: 18px;
  font-weight: $semi-bold;
}

/* Select items */
.ant-select-item {
  padding: 8px 12px;
}

/* Table */
.ant-table {
  z-index: 0 !important;
}

.ant-table-row-expand-icon {
  min-height: 20px;
  min-width: 20px;

  &:before {
    top: 50%;
    left: 50%;
    right: 0;
    height: 1px;
    transform: translate(-50%, -50%);
  }

  &-collapsed::before {
    transform: translate(-50%, -50%) rotate(-180deg);
  }

  &:after {
    top: 50%;
    left: 50%;
    bottom: 0;
    width: 1px;
    transform: translate(-50%, -50%) rotate(90deg);
  }

  &-collapsed::after {
    transform: translate(-50%, -50%) rotate(0deg);
  }
}

.ant-table-summary {
  background: #fafafa;
  font-weight: $semi-bold;
}

.ant-table-thead th.ant-table-column-sort {
  background: #fafafa;
}

td.ant-table-column-sort {
  background: white;
}

// Expanded table rows should have a different background color
tr[class^='ant-table-row ant-table-row-level-1'] {
  background: #fafafa;
}

/* Select */
.ant-select-dropdown {
  border-radius: 6px;
}

.ant-select-selector {
  border-radius: 6px !important;
}

.ant-typography u {
  border-bottom: 1px dotted #cccccc;
  text-decoration: none !important;
}

@keyframes become-visibile {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes vibrate {
  0% {
    transform: rotate(10deg);
  }

  25% {
    transform: rotate(-10deg);
  }

  50% {
    transform: rotate(0);
  }

  75% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(0);
  }
}

.cpohq-profile-avatar {
  position: relative;

  .cpohq-profile-avatar-upload-button {
    visibility: hidden;
    position: absolute;
    bottom: 24px;
    left: 22%;
  }

  &:hover .cpohq-profile-avatar-upload-button {
    visibility: visible;
  }
}

@keyframes pulse {
  0% {
    box-shadow: $profile-incomplete-field-highlight;
  }

  70% {
    box-shadow: $profile-incomplete-field-highlight-pulse;
  }

  100% {
    box-shadow: $profile-incomplete-field-highlight;
  }
}

.cpohq-profile-highlighted {
  .ant-avatar-circle {
    box-shadow: $profile-incomplete-field-highlight;
    animation: pulse 2s infinite;
  }

  &.ant-btn,
  &.avatar-uploader {
    box-shadow: $profile-incomplete-field-highlight;
    animation: pulse 2s infinite;
  }
}

.cpohq-company-logo {
  position: relative;
  display: flex;
  justify-content: center;

  .cpohq-company-logo-upload-button {
    visibility: hidden;
    position: absolute;
    bottom: 16px;
  }

  &:hover .cpohq-company-logo-upload-button {
    visibility: visible;
  }
}

.company-logo-bg-hover:hover {
  background-color: #f0f3fa;
}

.avatar-uploader > .ant-upload {
  width: 180px;
  height: 104px;
  margin: 0;
}

.collection-cover {
  .ant-upload {
    width: 100%;
    height: 140px;
    border-radius: 6px;

    &:hover {
      background-color: #e2ecff;
    }
  }
}

.collection-cover-image {
  height: 140px;
  border-radius: 6px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }

  .collection-cover-hover-buttons {
    display: none;
  }

  &:hover {
    .collection-cover-hover-buttons {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));

        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
      }
    }
  }
}

.profile-empty-state {
  width: 100%;
  margin: 48px 8px 96px;
}

.suggestion-modal-input > .ant-input {
  border-radius: 6px;
}

.onboarding-item .ant-list-item-meta-avatar {
  margin-right: 0;
}

.dropdown-select-item {
  padding: 8px 12px;
  background-color: #ffffff;
  transition: background 0.3s ease;
  width: 100%;
  border: none !important;
  outline: none !important;
  text-align: left;

  &:hover {
    background-color: #f5f5f5;
  }

  &-active {
    color: #000000d9;
    background-color: #e6f7ff;
    font-weight: 600;
  }

  &-active:hover {
    background-color: #e6f7ff;
  }
}

.clickable-table-row {
  color: #326cf0;

  &:hover {
    text-decoration: underline;
  }
}

.hover-item {
  :hover {
    .link-text {
      text-decoration: underline;
    }
  }
}

.ant-card {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
}

.material-symbols-outlined {
  /* keep consistent sizing before font loads */
  width: 1em;
  aspect-ratio: 1/1;

  /* MATERIAL ICONS RECOMMENDED STYLES */
  /* https://developers.google.com/fonts/docs/material_icons#styling_icons_in_material_design */
  /* Rules for sizing the icon. */
  &.md-18 {
    font-size: 18px;
  }
  &.md-24 {
    font-size: 24px;
  }
  &.md-36 {
    font-size: 36px;
  }
  &.md-48 {
    font-size: 48px;
  }
  /* Rules for using icons as black on a light background. */
  &.md-dark {
    color: rgba(0, 0, 0, 0.54);
  }
  &.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }

  /* Rules for using icons as white on a dark background. */
  &.md-light {
    color: rgba(255, 255, 255, 1);
  }
  &.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }
}
